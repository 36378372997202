/**
 * Handle back button on people page.
*/
document.addEventListener('DOMContentLoaded', function () {
    const backButton = document.querySelector('#people-back-button a');
    if (backButton) {
        const backButtonUrl = backButton.getAttribute('href');
        const url = window.location.href;
        const newuRL = new URL(backButtonUrl);
        const urlParams = new URLSearchParams(url);

        if (urlParams.has('loc')) {
            newuRL.searchParams.set('loc', urlParams.get('loc'));
        }
        if (urlParams.has('team')) {
            newuRL.searchParams.set('team', urlParams.get('team'));
        }
        newuRL.searchParams.set('limit', urlParams.get('limit') || 100);

        backButton.setAttribute('href', newuRL);

    }
});