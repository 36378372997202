jQuery(function ($) {
  $(function () {
    $("select").each(function () {
      $(this).styledUpDropDownList();
    });
  });

  (function ($) {
    $.fn.styledUpDropDownList = function () {
      var originalElement = this;
      originalElement.show();

      var newElement = $("<div class='styled-dropdown' tabindex='0'></div>");
      var paragraphElement = $("<p></p>");
      var listElement = $("<ul class='styled-dropdown-list'></ul>");

      paragraphElement.text(originalElement.children("option:selected").text());
      newElement.append(paragraphElement);

      $("html").click(function () {
        listElement.hide();
        newElement[0].classList.remove("active");
      });

      newElement.on("keydown", function (event) {
        if (event.keyCode === 40 && !listElement.is(":visible")) {
          event.preventDefault();
          $(".styled-dropdown-list").hide();
          listElement.show();
          newElement[0].classList.add("active");
          var selectedItem = listElement.find(".selected");

          if (selectedItem.length === 0) {
            selectedItem = $(listElement).children("li:first-child");
          }

          selectedItem.addClass("selected").focus();
        }

        // Up arrow
        if (event.keyCode === 38 && listElement.is(":visible")) {
          event.preventDefault();
          let selected = listElement.find(".selected");
          let prev = selected.prev();

          if (prev.length > 0) {
            selected.removeClass("selected");
            prev.addClass("selected").focus();
          } else {
            selected.removeClass("selected");
            listElement.hide();
            newElement[0].classList.remove("active");
          }
        }

        // Down arrow
        if (event.keyCode === 40 && listElement.is(":visible")) {
          event.preventDefault();
          let selected = listElement.find(".selected");
          let next = selected.next();

          if (next.length > 0) {
            selected.removeClass("selected");
            next.addClass("selected").focus();
          }
        }
      });

      paragraphElement.click(function (event) {
        if (listElement.is(":visible")) {
          listElement.hide();
          newElement[0].classList.remove("active");
        } else {
          $(".styled-dropdown-list").hide();
          listElement.show();
          newElement[0].classList.add("active");
        }
        event.stopPropagation();
      });

      originalElement.children().each(function () {
        var originalItem = $(this);
        var newItem = $("<li tabindex='0'>" + originalItem.text() + "</li>");
        newItem.attr("id", originalItem.val());

        newItem.click(function () {
          var selectedValue = $(this).attr("id");
          originalElement.find("option").removeAttr("selected");

          var originalOptionToSelect = originalElement.find(
            "option[value='" + selectedValue + "']"
          );

          originalOptionToSelect.attr("selected", "selected");
          originalOptionToSelect.trigger("change");
          paragraphElement.text($(this).text());
          let select = originalElement.closest("select");
          select.val(selectedValue);
          listElement.hide();
        });

        listElement.append(newItem);
      });

      listElement.on("keydown", "li", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          $(this).click();
          newElement.focus();
        }
      });

      listElement.hide();
      originalElement.hide();
      newElement.append(listElement);
      newElement.insertBefore(originalElement);
      return this;
    };
  })(jQuery);
});
