/**
 *Handle Carousel
 *
 * @package Sequel Studio
 */
(function ($, document) {
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  $(function () {
    const $carousel = $(".team-carousel__carousel");
    if (!$carousel.length) {
      return;
    }

    // Shuffle & slice the slides.
    // No more than 6, in a random order for every page visit.
    $carousel.each(function () {
      $self = $(this);
      $slides = $self.find(".team-carousel__slide").detach();
      shuffle($slides);
      $slides.slice(0, 6).each(function () {
        $carousel.append(this);
      });
    });

    var isDragging = false;

    $(".team-carousel__slide_click_zone")
      .mousedown(function () {
        isDragging = false;
      })
      .mousemove(function () {
        isDragging = true;
      })
      .mouseup(function () {
        var wasDragging = isDragging;
        isDragging = false;

        if (!wasDragging) {
          window.location = $(this)
            .parent()
            .find(".team-carousel__slide_link")[0].href;
        }
      });

    $(".team-carousel__slide > img").mouseenter(function () {
      $(
        ".team-carousel__slide_click_zone, .team-carousel__slide_overlay"
      ).fadeOut(300);

      $(this)
        .nextAll(".team-carousel__slide_overlay")
        .fadeIn(200, function () {
          $(this)
            .nextAll(".team-carousel__slide_click_zone")
            .css("display", "block");
        });
    });

    $(".team-carousel__slide_click_zone").mouseleave(function () {
      $(this)
        .prevAll(".team-carousel__slide_overlay")
        .fadeOut(200, function () {
          $(this)
            .nextAll(".team-carousel__slide_click_zone")
            .css("display", "none");
        });
    });

    $carousel.slick({
      infinite: true,
      speed: 300,
      autoplay: false,
      variableWidth: true,
      slidesToShow: 3,
      touchThreshold: 500,
      arrows: true,
      prevArrow: $(".team_carousel__arrow_left"),
      nextArrow: $(".team_carousel__arrow_right"),
      responsive: [
        {
          breakpoint: 792,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    });
  });
})(jQuery, document);
