/**
 * Scrolling animations
 *
 * @author Chris Smith
 * @package Sequel Studio
 */
(function($, document, gsap, ScrollTrigger){

    function duplicateChildren( parent ) {
        let clone = parent.cloneNode( true );

        Array.from(clone.children).forEach( ( clonedChild ) => { parent.appendChild( clonedChild ); });
    }

    function cw_animations() {
        if (!gsap) {
            return;
        }

        gsap.registerPlugin(ScrollTrigger);

        const WIPE               = ".sequel-section--wipe-on";
        const ANIMATEABLE_TEXT   = ".title-wrap, .description";
        const EYEBROW            = ".eyebrow";
        const TEAM_FEATURE       = ".team-feature .col-team";
        const TESTIMONIALS       = ".sequel_testimonials";
        const TESTIMONIAL_SLIDES = TESTIMONIALS + "_carousel__slide";
        const THREE_COLS_CARDS   = ".three-columns-media";
        const CTA_BUTTONS        = ".sequel-cta-button";

        // Breakpoints
        const DESKTOP      = 1024;
        const TABLET_LARGE = 880;
        const TABLET       = 768;

        const animation_defaults = {
            wipeup: {
                pin: true,
                pinSpacing: false,
                scrub: true
            },
            text: {
                once: true,
                start: "top bottom-=2%",
                toggleClass: "animated",
            },
            teamfeature: {
                scrub: true,
                start: "top bottom",
                end: "bottom top",
                toggleActions: "play pause resume pause",
            },
            testimonials: {
                pin: true,
                pinSpacing: true,
                scrub: true,
                start: "top top",
            },
            threecolumncards: {
                once: true,
                scrub: true,
                start: "top bottom",
                end: "bottom bottom",
            }
        };

        // Wipe up animation.
        const wipes = document.querySelectorAll(WIPE);
        wipes.forEach((wipe, i) => {

            const scene = wipe.previousElementSibling;
            if (!scene) {
                return;
            }

            const options = { trigger: scene };

            // Pin the top when scene is shorter than the window height, otherwise pin the bottom.
            if ($(scene).height() > $(window).height()) {
                options.start = "bottom bottom";
            } else {
                options.start = "top top";
            }

            options.end = "top top";
            options.endTrigger = wipe;

            ScrollTrigger.create(Object.assign({}, animation_defaults.wipeup, options));
        });

        // Text animation.
        const textblocks = document.querySelectorAll(ANIMATEABLE_TEXT);
        textblocks.forEach((text, i) => {
            if (text.classList.contains('title-wrap')) {
                let lh = parseFloat(getComputedStyle(text).getPropertyValue('line-height'));
                let lines = text.clientHeight / lh;
                let titleAnimationLength = 1 + Math.max(0, lines - 1) * 0.3;
                text.style.setProperty('--title-animation-length', "" + titleAnimationLength + "lh");
            }
            if (text.closest('.animateable-text') && !text.closest('.disable-default-animation')) {
                const options = { trigger: text };
                ScrollTrigger.create(Object.assign({}, animation_defaults.text, options));
            }
        });

        // Eyebrow animation (use text defaults).
        const eyebrows = document.querySelectorAll(EYEBROW);
        eyebrows.forEach((item, i) => {

            // Check to see if eyebrow animation is being handled elsewhere, e.g. three columns.
            if (item.closest( '.disable-default-animation' )) {
                return;
            }

            const options = { trigger: item };
            ScrollTrigger.create(Object.assign({}, animation_defaults.text, options));
        });

        // CTA button animation (use text defaults).
        const cta_buttons = document.querySelectorAll(CTA_BUTTONS);
        cta_buttons.forEach((button, i) => {
            if (button.closest('.animateable-button') && !button.closest('.disable-default-animation')) {

                const label = button.querySelector('.sequel-cta-button-label i');

                button.style.setProperty('--cta-label-width', (label.clientWidth + 1) + 'px');
                button.style.setProperty('--cta-label-height', label.clientHeight + 'px');
                button.classList.add('prepped');

                const options = { trigger: button };
                ScrollTrigger.create(Object.assign({}, animation_defaults.text, options));
            }
        });

        // Team feature animation
        const teamfeature = document.querySelectorAll(TEAM_FEATURE);
        teamfeature.forEach((item, i) => {
            const options = {
                trigger: item,
            };

            const colLeft  = item.querySelector('.col-team-left .col-team-inner--wrap');
            const colRight = item.querySelector('.col-team-right .col-team-inner--wrap');

            function speed(el, axis) {
                let i = ( axis === 'x' ) ? 1 : 0;
                return el.dataset.speed?.split(',')?.[ i ] ?? 1;
            }

            function addTween(el, container, axis, direction, speed, timeline) {
                let dimension       = ( axis === 'x' ) ? 'Width' : 'Height';
                let clientDimension = 'client' + dimension;
                let innerDimension  = 'inner' + dimension;
                let duration        = el[ clientDimension ] - container[ clientDimension ];
                let tweenOptions    = {};

                if (duration > 0) {
                    // When not enough people to meet speed, double up
                    if (duration < speed * window[ innerDimension ] ) {
                        duplicateChildren( el );
                        duration = el[ clientDimension ] - container[ clientDimension ];
                    }

                    duration             = Math.min( duration, speed * window[ innerDimension ] );
                    tweenOptions[ axis ] = ( direction === '-' ? -1 : 1 ) * duration;
                    timeline.to(el, tweenOptions, "start");
                    return true;
                }

                return false;
            }

            let mm = gsap.matchMedia();

            mm.add(`(min-width: ${TABLET_LARGE}px)`, () => {

                const timeline = gsap.timeline();
                addTween(colLeft, item, 'y', '-', speed( colLeft, 'y' ), timeline);
                addTween(colRight, item, 'y', '-', speed( colRight, 'y' ), timeline);

                options.animation = timeline;
                ScrollTrigger.create(Object.assign({}, animation_defaults.teamfeature, options));
            });

            mm.add(`(max-width: ${TABLET_LARGE-1}px)`, () => {

                const timeline = gsap.timeline();
                addTween(colLeft, item, 'x', '-', speed( colLeft, 'x' ), timeline);
                addTween(colRight, item, 'x', '+', speed( colRight, 'x' ), timeline);

                options.animation = timeline;
                ScrollTrigger.create(Object.assign({}, animation_defaults.teamfeature, options));
            });

        });

        // Testimonials animation
        const testimonials = document.querySelectorAll(TESTIMONIALS);
        testimonials.forEach((item, i) => {

            const section  = item.closest('.sequel-section--page');
            const slides   = item.querySelectorAll(TESTIMONIAL_SLIDES);
            const wrapper  = slides[0]?.parentElement;
            const carousel = wrapper?.parentElement;

            if (!section || !slides || !wrapper || !carousel) {
                console.log("TESTIMONIALS CAROUSEL: Couldn't initiate carousel scroll animation, unexpected HTML structure.");
                return;
            }

            if (1 === slides.length) {
                // Nothing to animate.
                return;
            }

            const timeline      = gsap.timeline();
            const gap           = slides[1].offsetLeft - slides[0].clientWidth;
            const scrollLength  = Math.max(0, wrapper.clientWidth - carousel.clientWidth);
            const visibleSlides = carousel.clientWidth / (slides[0].clientWidth + gap);
            const scrollDelay   = Math.min(wrapper.clientWidth - scrollLength, slides[0].clientWidth) / 2;
            const totalDuration = scrollLength + scrollDelay * 2;
            const slideDuration = totalDuration / slides.length;

            if (scrollLength > 0) {
                timeline.to( {}, { duration: scrollDelay } );
                timeline.to(carousel, { scrollLeft: scrollLength, duration: scrollLength });
                timeline.to( {}, { duration: scrollDelay } );
            }

            // Slides
            function calcStartPoint(slideIndex) {
                return (slideIndex * slideDuration) - (slideDuration + gap) / 2;
            }

            slides.forEach( (slide, i) => {

                if (i) {
                    timeline.fromTo(slide, { opacity: 0.3 }, { opacity: 1, duration: gap }, calcStartPoint(i));
                } else {
                    timeline.set(slide, {opacity: 1}, 0);
                }
                if (i + 1 !== slides.length) {
                    timeline.fromTo(slide, { opacity: 1}, { opacity: 0.3, duration: gap}, calcStartPoint(i+1));
                }
            });

            const options = {
                trigger: section.children[0],
                animation: timeline,
                end: `+=${totalDuration}`,
                onLeave: ScrollTrigger.refresh(true),
            };

            ScrollTrigger.create(Object.assign({}, animation_defaults.testimonials, options));
            carousel.classList.add('prepped');
        });

        // Three column cards animation
        // Animation is only applied to the last occurence of this component on the page.
        // There should only be one and it should be the component immediately prior to the footer.
        const threeColCard = Array.from(document.querySelectorAll(THREE_COLS_CARDS)).slice(-1);
        threeColCard.forEach((item, i) => {

            const options = {
                trigger: item,
            };

            let mm = gsap.matchMedia();

            mm.add(`(min-width: ${DESKTOP}px)`, () => {

                const timeline = gsap.timeline();
                const columns = item.querySelectorAll('.column');

                columns.forEach((col, i) => {
                    let delay = i * 0.2;
                    timeline.to(col, { y: 0, duration: 0.6 }, delay);
                });

                options.animation = timeline;
                ScrollTrigger.create(Object.assign({}, animation_defaults.threecolumncards, options));
            });

            // Stacked, only animate the text
            mm.add(`(max-width: ${DESKTOP-1}px)`, () => {

                ScrollTrigger.create(Object.assign({}, animation_defaults.text, options));
            });

        });
    }

    document.addEventListener("DOMContentLoaded", function(event){

        // Wait until images, links, fonts, stylesheets, and js is loaded
        window.addEventListener("load", function(e){

            // GSAP animations
            // Only run animations when animations-js class is found.
            if (document.documentElement.classList.contains('animations-js')) {
                // But not when running inside the elementor editor.
                if (!document.body.classList.contains('elementor-editor-active')) {
                    cw_animations();
                    ScrollTrigger.sort();
                }
            }

        }, false);

    });


})(jQuery, document, gsap, ScrollTrigger);