addEventListener("load", (event) => {});

onload = (event) => {
  if (typeof stats !== "undefined") {
    stats.forEach((e) => lottie_scroll(e));
  }

  var players = document.getElementsByClassName("lottie-player");

  for (let i = 0; i < players.length; i++) {
    lottie_scroll(players[i].id);
  }

  var chain_players = document.getElementsByClassName("chain-lottie-player");

  for (let i = 0; i < chain_players.length; i++) {
    lottie_chain(chain_players[i].id);
  }
};

function lottie_scroll(player_id) {
  var player = document.getElementById(player_id);
  var frame_from = player.getAttribute("data-frame-from");
  var frame_to = player.getAttribute("data-frame-to");

  var actions = [
    {
      visibility: [0, 0.1],
      type: "stop",
    },
    {
      visibility: [0.1, 1.0],
      type: "playOnce",
    },
  ];

  if (frame_from && frame_to) {
    actions[1].frames = [JSON.parse(frame_from), JSON.parse(frame_to)];
  }

  LottieInteractivity.create({
    mode: "scroll",
    player: "#" + player_id,
    actions: actions,
  });
}

function lottie_chain(player_id) {
  LottieInteractivity.create({
    player: "#" + player_id,
    mode: "chain",
    actions: [
      {
        state: "autoplay",
        transition: "click",
        frames: [0, 100],
      },
      {
        state: "autoplay",
        transition: "click",
        frames: [101, 200],
      },
      {
        state: "autoplay",
        transition: "click",
        frames: [201, 300],
      },
    ],
  });
}
