jQuery(function ($) {

  const KEY_ESCAPE   = 27;
  const $alertBanner = $('.alert-banner');

  if (!$alertBanner.length) {
    return;
  }

  const alertId = $alertBanner.data('alert-id') ?? 'cwag-alert';
  const alertLife = $alertBanner.data('alert-life' ?? 7);

  function closeAlert() {
    $alertBanner.off("keydown.alert");
    $alertBanner.closest(".e-con").animate(
      {
        height: "toggle",
      },
      250,
      function () {
        setCookie(alertId, 1, alertLife);
      }
    );
  }

  $alertBanner.find(".alert-banner-close-button").on("click", function (e) {
    e.preventDefault();
    closeAlert();
  });

  $alertBanner.on("keydown.alert", function (e) {
    if (e.which === KEY_ESCAPE) {
      e.stopPropagation();
      e.preventDefault();
      closeAlert();
    }
  });
});
