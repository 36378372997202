if (document.querySelectorAll('.sequel-accordion')) {

    // Foreach accordion.
    document.querySelectorAll('.sequel-accordion').forEach(accordion => {
        const accordionHeadings = accordion.querySelectorAll('.accordion__heading');

        /*
         * Some notes:
         *
         * aria-expanded controls display: none.  Necessary for focus order & screen readers
         * active class controls opening and closing of the accordion item.
         * animating class combines with active to set an animateable pixel height.
         */

        function accordionToggle(heading, clearOthers) {
            const item = heading.parentElement;
            const desc = heading.nextElementSibling.children[0];
            let open   = item.classList.contains('active');

            if ( open ) {
                // Recalculate description height in case it's changed since opening.
                item.style.setProperty('--accordion-content-height', "" + desc.clientHeight + "px");
                item.classList.add('animating');

                // Ensure a reflow between adding 'animating' and removing 'active' to allow px height to take effect.
                window.requestAnimationFrame(function() {
                    window.requestAnimationFrame(function() {
                        item.classList.remove('active');
                    });
                });

                // Don't clear aria-expanded here, needs to be done after the transition completes.
            } else {
                // Set aria-expanded first, to remove display:none and allow height to be calculated.
                heading.setAttribute('aria-expanded', 'true');
                item.style.setProperty('--accordion-content-height', "" + desc.clientHeight + "px");
                item.classList.add('active', 'animating');

                // Only need to clearOthers when opening.
                if (clearOthers) {
                    accordionHeadings.forEach( otherHeading => {
                        if (otherHeading !== heading) {
                            if (otherHeading.parentElement.classList.contains('active')) {
                                accordionToggle(otherHeading, false);
                            }
                        }
                    });
                }
            }
        }

        accordionHeadings.forEach(heading => {

            const item        = heading.parentElement;
            const description = heading.nextElementSibling;

            heading.addEventListener('click', ( evt ) => {
                evt.preventDefault();
                accordionToggle(heading, true);
            });
            heading.addEventListener('keydown', function( evt ) {
                switch (evt.keyCode) {
                    case 32: // Space.
                        evt.preventDefault();
                        break;

                    case 13: // Enter
                        evt.preventDefault();
                        accordionToggle(heading, true);
                        break;
                }
            });
            heading.addEventListener('keyup', function( evt ) {
                if (evt.keyCode === 32) {  // Space.
                    evt.preventDefault();
                    accordionToggle(heading, true);
                }
            });

            description.addEventListener('transitionend', () => {
                const open = item.classList.contains('active');

                if (!open) {
                    heading.setAttribute('aria-expanded', 'false');
                }

                item.classList.remove('animating');
            });
        });
    });

}