jQuery(function ($) {

  const $scrollButtons = $(".scroll-to-next-section");

  $scrollButtons.each(function(){
    const $scrollBtn     = $(this);
    const $heroContainer = $scrollBtn.closest(".e-parent");

    let $sectionNext = $heroContainer.next();

    // Section could be in a pin spacer, need to go up one more level.
    if (!$sectionNext.length) {
      $sectionNext = $heroContainer.parent().next();
    }

    // Couldn't find the next section, hide the button (but keep its space).
    if (!$sectionNext.length) {
      window.requestAnimationFrame(() => {
        $scrollBtn.css('visibility', 'hidden').attr('aria-hidden', 'true');
      });
      return;
    }

    $scrollBtn.on("click", function (e) {
      e.preventDefault();

      if (!$sectionNext.attr('tabindex')) {
        $sectionNext.attr("tabindex", "-1");
      }
      $sectionNext.focus();

      $("html, body").animate({
        scrollTop: $sectionNext.offset().top,
      });
    });

  });

});
