document.addEventListener("DOMContentLoaded", function () {
  const button         = document.querySelector(".single-location .col-copy a");
  const isTeamButton   = (button.getAttribute("href").indexOf('team') !== -1)
  const isLocationPage = (window.location.href.indexOf("/location/") !== -1);

  if (isTeamButton && isLocationPage) {
    const teamButtonUrl = button.getAttribute("href");
    const location = new URL(window.location.href).pathname
      .replace("/location/", "")
      .replace("/", "");

    const newTeamButtonUrl = teamButtonUrl + "?loc=" + location + '#team';
    button.href = newTeamButtonUrl;
  }
});
