/**
 * Header javascript
 *
 * @author Andres Hernandez
 * @package Sequel Studio
 */

const NAVBAR_BACKGROUND_CSSVAR = "--sql-navbar-background-color";

const targetDiv = document.querySelector("#menu-wrapper");

const defaultLogo = document.querySelector(
  "#header-wrapper #menu-wrapper .default"
);

const stickyLogo = document.querySelector(
  "#header-wrapper #menu-wrapper .sticky"
);

let headerScrolled = false;
const hamburger = document.querySelector(".menu-hamburger");

function change_header_color(
  defaultLogoDisplay,
  stickyLogoDisplay,
  backgroundColor
) {
  targetDiv.style.setProperty(NAVBAR_BACKGROUND_CSSVAR, backgroundColor);
  defaultLogo.style.display = defaultLogoDisplay;
  stickyLogo.style.display = stickyLogoDisplay;
}

// Initialize based on initial scroll position.
if (window.scrollY > 0) {
  change_header_color("none", "flex", "var(--e-global-color-primary)");
  headerScrolled = true;
}

// Function to be used in requestAnimationFrame() calls
function delayed_header_update() {
  if (headerScrolled) {
    change_header_color("none", "flex", "var(--e-global-color-primary)");
  } else {
    change_header_color("", "", "");
  }
}

window.addEventListener("scroll", function () {
  var scrollPosition = window.scrollY;

  if (scrollPosition > 0) {
    if (!headerScrolled) {
      window.requestAnimationFrame(() => {
        delayed_header_update();
      });
      headerScrolled = true;
    }
  } else {
    if (headerScrolled) {
      window.requestAnimationFrame(() => {
        delayed_header_update();
      });
      headerScrolled = false;
    }
  }
});

jQuery(function ($) {
  $(".menu-hamburger").on("click", function (e) {
    e.preventDefault();

    if (!$(this).hasClass("open")) {
      $(".menu-dropdown").removeClass("open");

      $(".menu-hamburger img").attr(
        "src",
        sequel.sequelstudio_theme_uri + "/assets/images/cross.svg"
      );

      if (!headerScrolled) {
        $("#menu-wrapper").css(
          NAVBAR_BACKGROUND_CSSVAR,
          "var(--e-global-color-primary)"
        );
        $("#menu-wrapper > .menu-center > a > .default").css("display", "none");
        $("#menu-wrapper > .menu-center > a > .sticky").css("display", "flex");
      }

      $("body").addClass("mobilemenu-open");
      $(".menu-hamburger").addClass("open");
      $("#menu-wrapper").addClass("responsive");
    } else {
      $(".menu-hamburger img").attr(
        "src",
        sequel.sequelstudio_theme_uri + "/assets/images/hamburger.svg"
      );

      if (!headerScrolled) {
        $("#menu-wrapper").css(NAVBAR_BACKGROUND_CSSVAR, "");
        $("#menu-wrapper > .menu-center > a > .default").css("display", "");
        $("#menu-wrapper > .menu-center > a > .sticky").css("display", "");
      }

      $("body").removeClass("mobilemenu-open");
      $(".menu-hamburger").removeClass("open");
      $("#menu-wrapper").removeClass("responsive");
    }
  });

  $(".menu-dropdown > a").on("click", function (e) {
    e.preventDefault();

    if (!$(this).parent().hasClass("open")) {
      $(".menu-dropdown").removeClass("open");
      $(this).parent().addClass("open");
    } else {
      $(this).parent().removeClass("open");
    }
  });
});
